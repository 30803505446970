<template>
  <div class="container text-center bg-white pt-4">
    <div class="container_main px-5 justify-content-center d-flex flex-column">

        <h1 class="mx-5 mb-4">
            <strong>Thank you!</strong>
        </h1>
        <p class="mx-5 mb-5">
            Your message has been sent.
        </p>

    </div>
    <div class="container_footer p-3">
      <b-button variant="primary" class="d-block w-100 p-2" @click="$router.push('/main/result')">Back</b-button>
    </div>
  </div>
</template>

<script>
export default {
  middleware: 'check-selection',
}
</script>